import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { Gallery } from "./components/gallery";
import { Contact } from "./components/contact";
import { Elektronika } from "./components/elektronika";
import { Programowanie } from "./components/programowanie";
import { Druk3d } from "./components/druk3d";
import { Montaz } from "./components/montaz";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import CookieConsent from "react-cookie-consent";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Navigation />
      <Header data={landingPageData.Header} />
      <Features data={landingPageData.Features} />
      <Elektronika data={landingPageData.elektronika} />
      <Programowanie data={landingPageData.programowanie} />
      <Druk3d data={landingPageData.druk3d} />
      <Montaz data={landingPageData.montaz} />
      <Gallery data={landingPageData.Gallery} />
      <Contact data={landingPageData.Contact} />

      <CookieConsent
        location="bottom"
        buttonText="Akceptuje"
        declineButtonText="Odrzuć"
        cookieName="myCookieConsent"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={365}
      >
        Używamy plików cookie, aby usprawnić korzystanie z naszej strony internetowej.{" "}
        <a href="/polityka-prywatnosci" style={{ color: "#5ca9fb" }}>
          Dowiedz się więcej
        </a>
      </CookieConsent>
    </div>
  );
};

export default App;
