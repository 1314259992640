import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";

const initialState = {
  name: "",
  email: "",
  message: "",
  company: "",
  phone: "",
};
export const Contact = (props) => {
  const [{ name, email, message, company, phone }, setState] = useState(initialState);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });
  
  
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, message);
    
    {/* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */ }
    
    emailjs
      .sendForm( "service_h9wtu4o", "template_8o9tbgq", e.target, "KIGG0CQ9LqMGxjhAa")
      .then(
        (result) => {
          console.log(result.text);
          clearState();
          setIsSubmitted(true); // Ustawia potwierdzenie wysyłki na true
          setTimeout(() => setIsSubmitted(false), 10000); // 5 sekund zwłoki
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div>
      <div id="contact">
        <div className="container">
        <div className="contact-all">
          <div className="col-md-7">
            <div className="row">
              <div className="section-title">
                <h2>Zadaj pytanie!</h2>
                <p>
                  Możesz zadać pytanie poprzez formularz, mailowo lub telefonicznie.
                </p>
              </div>
              
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Imię"
                        required
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Adres email"
                        required
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="company"
                        name="company"
                        className="form-control"
                        placeholder="Nazwa firmy"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="tel"
                        id="phone"
                        name="phone"
                        className="form-control"
                        placeholder="Numer telefonu"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Wiadomość"
                    required
                    onChange={handleChange}
                  ></textarea>
                </div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Wyślij wiadomość
                </button>

                {isSubmitted && (
                <div className="alert alert-success">
                  Wiadomość została wysłana pomyślnie!
                </div>
          )}
              </form>
            </div>
          </div>
          </div>
          <div className="col-md-4">
            <div className="contact-item">
              <h3>Kontakt</h3>
              <p>
                <span>
                  <i className="fa fa-briefcase"></i> Dane firmy
                </span>
                <div>{props.data ? props.data.address1 : "loading"}</div>
                <div>{props.data ? props.data.address4 : "loading"}</div>
                <div>{props.data ? props.data.address2 : "loading"}</div>
                <div>{props.data ? props.data.address3 : "loading"}</div>
                

              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Telefon
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2023 Issaaf Kattan React Land Page Template. Design by{" "}
            <a href="http://www.templatewire.com" rel="nofollow">
              TemplateWire
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
